import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }
  statusList: any = [];
  isActiveList: any = [];
  haveFlavourList: any = [];
  typeList: any = [];
  

  convertOrderTypeText (id) {
    var status = '';
    switch (id) {
        case 1:
            status = "In-house Delivery";
            break        
        case 2:
            status = "Food Panda";
            break
        case 3:
            status = "Meal Plan";
            break
            case 4:
            status = "Pathao";
            break
        case 5:
            status = "Pick-Up";
            break
        case 6:
          status = "HungryNaki";
          break
        case 7:
        status = "Free Delivery";
        case 8:
          status = "Dine-In";
        case 9:
          status = "Dine-In";
        break
         case 10:
          status = "Pandago";
        break
    }
    return status
}


convertKitchenText (id) {
  var status = '';
  switch (id) {
      case 0:
          status = "Hungry Rooster";
          break   
      case 1:
          status = "Pizzamia";
          break        
      case 2:
          status = "Hungry Rooster";
          break
      case 3:
          status = "Pimentos";
          break
      case 4:
          status = "Shukraan";
      break
      case 5:
          status = "Jhotpot";
          break
  }
  return status
}

getOrderTypeList (){
  this.typeList = [
    {
      id: 1,
      name: "In-house Delivery"
    },
    {
      id: 8,
      name: "Dine-In"
    },
    {
      id: 5,
      name: "Pick-Up"
    },
    {
      id: 2,
      name: "Foodpanda"
    },
    {
      id: 9,
      name: "Pandago"
    },
    {
      id: 3,
      name: "Meal Plan"
    },
    {
      id: 4,
      name: "Pathao"
    },
    // {
    //   id: 6,
    //   name: "HungryNaki"
    // },
    {
      id: 7,
      name: "Free Delivery"
    }
  ]
  return this.typeList;
}
convertOrderStatusText (id) {
    var status = '';
    switch (id) {
        case 0:
            status = "Pending";
            break
        case 1:
            status = "Preparing Food";
            break
        case 2:
            status = "Food is Ready";
            break
        case 3:
            status = "Dispatched";
            break
        case 4:
            status = "Delivered";
            break
        case 5:
            status = "Cancel";
            break
    }
    return status
}
getStatusList(){
    this.statusList = [
      {
          id: 0,
          name: "Pending"
      },
      {
        id: 1,
        name: "Preparing Food"
      },
      {
        id: 2,
        name: "Food is Ready"
      },
      {
        id: 3,
        name: "Dispatched"
      },
      {
        id: 4,
        name: "Delivered"
      },
      {
        id: 5,
        name: "Cancel"
      }
    ]
    return this.statusList;
}

getIsActiveList(){
  this.isActiveList = [
    {
        id: 0,
        name: "Inactive"
    },
    {
      id: 1,
      name: "Active"
    }
  ]
  return this.isActiveList;
}

getHaveFlavourList(){
  this.haveFlavourList = [
    {
        id: 0,
        name: "No"
    },
    {
      id: 1,
      name: "Yes"
    }
  ]
  return this.haveFlavourList;
}

}


